import ContentLoader from 'react-content-loader';

const ProductLoader = () => (
	<div
		style={{
			width: '100%',
			maxWidth: 225,
			paddingTop: '71.11111111111111%', // (160 / 950) * 100 = 16.84%
			position: 'relative',
			backgroundColor: '#fff',
			borderRadius: '10px',
			border: '1px solid #f3f3f3',
			height: '350px'
		}}>
		<ContentLoader
			speed={2}
			viewBox='0 0 225 373'
			backgroundColor='#f3f3f3'
			foregroundColor='#ecebeb'
			style={{
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%'
			}}>
			<rect x='12' y='14' rx='0' ry='0' width='200' height='186' />
			<rect x='12' y='210' rx='0' ry='0' width='200' height='20' />
			<rect x='12' y='240' rx='0' ry='0' width='100' height='20' />
			<rect x='12' y='270' rx='0' ry='0' width='30' height='10' />
			<rect x='12' y='290' rx='0' ry='0' width='50' height='15' />
			<rect x='12' y='320' rx='10' ry='10' width='90%' height='40' />
		</ContentLoader>
	</div>
);

export default ProductLoader;
