'use client';

import { getImage, slugify } from '@/services/utils/helper';
import Link from 'next/link';
import Image from 'next/image';
import styles from './featured-brands.module.scss';

const FeaturedBrandSingle = ({ data }) => {
	const id = data.pb_id;
	const slug = slugify(data?.pb_name);
	const link = `/brand/${id}/${slug}`;
	return (
		<Link href={link || '/'}>
			<div className={styles.featured_brand_item}>
				<Image
					src={getImage(data.attachedFiles_pb_logo)}
					alt={data.pb_name}
					width={150}
					height={150}
					style={{ objectFit: 'contain' }}
					unoptimized
				/>
			</div>
		</Link>
	);
};

export default FeaturedBrandSingle;
