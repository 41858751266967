import { isEqual } from 'lodash';
import { useRef } from 'react';

function useDeepCompareMemo(value) {
	const ref = useRef(); // Import useRef from react

	if (!isEqual(value, ref.current)) {
		// Import isEqual from lodash
		ref.current = value;
	}

	return ref.current;
}

export default useDeepCompareMemo;
