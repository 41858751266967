import Image, { StaticImageData } from 'next/image';
import styles from './styles/img.module.scss';
import { IM_BROCKEN } from '@/assets/images';

interface Props {
	alt?: string;
	src: string | StaticImageData | undefined;
}
const Img: React.FC<Props> = ({ alt, src }) => (
	<div className={`${styles.img_wrap}`}>
		<Image
			alt={alt || ''}
			src={src || IM_BROCKEN}
			fill
			unoptimized={true}
			sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
		/>
	</div>
);

export default Img;
