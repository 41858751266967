'use client';

import { IM_BROCKEN } from '@/assets/images';
import { Product } from '@/services/types/productTypes';
import { ProductSummary } from '@/services/utils/helper';
import Link from 'next/link';
import AddToCart from '../../molecules/AddToCart';
import Img from '../../molecules/img';
import styles from './product-card-beauty.module.scss';

interface ProdcutCardProps {
	data: Product;
}

const ProductCardBeauty = ({ data }: ProdcutCardProps) => {
	const { p_name, p_strength, attachedFiles_p_images, pv } = data;
	const checkIsImage = (src: any) => {
		if (src?.length > 0) {
			return src[0].src;
		}
		return IM_BROCKEN.src;
	};
	const { profit_percent, mrp, price, p_allow_sales, variant } = ProductSummary(pv[0]);
	const slugify = (text: any) => {
		return text;
	};

	return (
		<div className={styles.product_wrap}>
			<div className={`${styles.product} `}>
				<Link href={'/'}>
					<div className={styles.img_wrap}>
						<Img alt={p_name} src={checkIsImage(attachedFiles_p_images)} />
					</div>
				</Link>
				<div className={`${styles.product_details} `}>
					<h3>
						<Link href={`product/${data.id}/${slugify(p_name)}`}>
							{p_name}
							<span className='ml-5'>{p_strength}</span>
						</Link>
					</h3>
					<div className={styles.price + ' swiper-no-swiping'}>
						<del className='text-10 text-grey-500 fw-400'>৳{mrp}</del>{' '}
						<span className='text-12 text-error fw-700'>{profit_percent} % OFF</span>
						<h4>৳{price}</h4>
						<AddToCart
							product={data}
							variant={variant}
							inputStyle={{
								minWidth: 'auto'
							}}
							label={<span className='p-14'>Add to Cart</span>}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductCardBeauty;
