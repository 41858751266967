// components/YouTubeModal.js
'use client';
import { useState } from 'react';
import Modal from '../Modal';
import styles from './YouTubeModal.module.scss';
import { MdOutlineClose } from 'react-icons/md';
function createYouTubeEmbedUrl(videoId, params = {}) {
	const baseUrl = `https://www.youtube.com/embed/${videoId}`;
	const url = new URL(baseUrl);

	const defaultParams = {
		autoplay: 1,
		controls: 1,
		cc_load_policy: 0,
		disablekb: 1,
		enablejsapi: 1,
		iv_load_policy: 1,
		playsinline: 0,
		rel: 0,
		color: 'red'
	};

	// Merge default parameters with any provided parameters
	const mergedParams = { ...defaultParams, ...params };

	Object.keys(mergedParams).forEach((key) => {
		url.searchParams.append(key, mergedParams[key]);
	});

	return url.toString();
}

export default function YouTubeModal({ videoKey, children }) {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpenModal = () => {
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	const videoUrl = createYouTubeEmbedUrl(videoKey);

	const modalContent = isOpen && (
		<Modal
			onClose={handleCloseModal}
			isOpen={isOpen}
			footer={null}
			width={900}
			bodyStyle={{
				padding: 0,
				height: 500,
				overflow: 'hidden',
				backgroundColor: '#000',
				position: 'relative'
			}}>
			<div
				style={{
					position: 'absolute',
					top: 10,
					right: 10,
					zIndex: 1,
					cursor: 'pointer'
				}}
				onClick={handleCloseModal}>
				<MdOutlineClose
					style={{
						color: '#FFF'
					}}
					size={24}
				/>
			</div>
			<iframe
				height={500}
				width={900}
				className={styles.iframe}
				src={videoUrl}
				frameBorder='0'
				allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			/>
		</Modal>
	);

	return (
		<>
			<div onClick={handleOpenModal}>{children}</div>

			{modalContent}
		</>
	);
}
